var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.PodcastDetail && Object.keys(_vm.PodcastDetail).length)?_c('div',{staticClass:"text-detail",style:(_vm.formattedImageList.length ? 'padding-top: 0 !important' : '')},[(_vm.formattedImageList && _vm.formattedImageList.length)?_c('TopBlock',{attrs:{"title":_vm.PodcastDetail.name,"subtitle":_vm.PodcastDetail.subtitle,"description":_vm.PodcastDetail.description,"images":_vm.formattedImageList}}):_vm._e(),_c('div',{staticClass:"content"},[(!_vm.formattedImageList.length)?_c('div',{staticClass:"text-detail__title",style:(("color: " + (_vm.PodcastDetail.color))),domProps:{"innerHTML":_vm._s(_vm.PodcastDetail.name)}}):_vm._e(),(!_vm.formattedImageList.length)?_c('div',{staticClass:"text-detail__sub-title",style:(("color: " + (_vm.PodcastDetail.color))),domProps:{"innerHTML":_vm._s(_vm.PodcastDetail.subtitle)}}):_vm._e()]),_c('Breadcrumbs',{attrs:{"pages":[
        { name: 'Библиотека', link: { name: 'Library' } },
        {
          name: 'Подкасты',
          link: {
            name: 'Podcasts',
          },
        },
        {
          name: _vm.PodcastDetail.name,
        } ],"color":'#b2b4b4'}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-detail__row mt-8"},[_c('div',{staticClass:"text-detail__body"},[_c('div',{staticClass:"material__tags-wrap"},[_c('div',{staticClass:"material__tags"},[(_vm.PodcastDetail.type)?_c('div',{staticClass:"material__tag"},[_vm._v(" "+_vm._s(_vm.PodcastDetail.type.name)+" ")]):_vm._e(),_vm._l((_vm.PodcastDetail.nosology),function(nosology){return _c('div',{key:("nos" + (nosology.id)),staticClass:"material__tag",domProps:{"innerHTML":_vm._s(nosology.title)}})}),_vm._l((_vm.PodcastDetail.disease),function(disease){return _c('div',{key:("dis" + (disease.id)),staticClass:"material__tag",domProps:{"innerHTML":_vm._s(disease.title)}})}),(_vm.PodcastDetail.is_new && false)?_c('div',{staticClass:"material__tag material__tag_new"},[_vm._v(" Новинка ")]):_vm._e()],2),(!_vm.$route.query.access)?_c('Favorite',{staticClass:"large ml-auto ml-xs-0",attrs:{"watchLater":_vm.PodcastDetail.watch_later,"favorite":_vm.PodcastDetail.favorite},on:{"watchLater":function($event){return _vm.watchLater(
                  _vm.PodcastDetail.watch_later ? 'DELETE' : 'POST',
                  _vm.PodcastDetail.slug,
                  null,
                  true
                )},"favorite":function($event){return _vm.favorite(
                  _vm.PodcastDetail.favorite ? 'DELETE' : 'POST',
                  _vm.PodcastDetail.slug,
                  null,
                  true
                )}}}):_vm._e()],1),_c('div',{domProps:{"innerHTML":_vm._s(_vm.PodcastDetail.interesting_facts)}}),(!_vm.formattedImageList.length)?_c('div',{staticClass:"text-detail__subtitle",domProps:{"innerHTML":_vm._s(_vm.PodcastDetail.description)}}):_vm._e(),(_vm.PodcastDetail.hosts && _vm.PodcastDetail.hosts.length)?_c('div',{staticClass:"text-detail__speakers mb-8"},[_c('div',{staticClass:"text-detail__speakers-header"},[_c('div',{staticClass:"text-detail__speakers-title",style:(("color: " + (_vm.PodcastDetail.color)))},[_vm._v(" Спикеры выпуска ")])]),_c('div',{staticClass:"text-detail__speakers-list"},_vm._l((_vm.PodcastDetail.hosts),function(speaker,ind){return _c('div',{key:ind,staticClass:"text-detail__speaker"},[_c('div',{staticClass:"text-detail__speaker-image-container"},[_c('img',{staticClass:"text-detail__speaker-image",attrs:{"src":speaker.photo}})]),_c('div',{staticClass:"text-detail__speaker-tag"},[_vm._v("Спикер")]),_c('div',{staticClass:"text-detail__speaker-text"},[_c('div',{staticClass:"text-detail__speaker-name"},[_vm._v(" "+_vm._s(speaker.full_name)+" ")]),_c('div',{staticClass:"text-detail__speaker-description",domProps:{"innerHTML":_vm._s(speaker.position)}})])])}),0)]):_vm._e(),_vm._l((_vm.PodcastDetail.episodes),function(episode,ind){return _c('PodcastEpisode',{key:ind,attrs:{"ind":ind,"background_color":_vm.PodcastDetail.background_color,"color":_vm.PodcastDetail.color,"arrow":_vm.PodcastDetail.episodes.length > 1,"episode":episode},on:{"timingCompleted":_vm.timingCompleted}})}),(_vm.PodcastDetail.sources)?_c('InfoDrop',{attrs:{"active":true,"item":{ title: 'Источники', content: _vm.PodcastDetail.sources }}}):_vm._e(),(
              _vm.PodcastDetail.survey && _vm.filteredSurveyQuestions.questions.length
            )?_c('div',{staticClass:"button button_pink mt-8",on:{"click":_vm.openSurvey}},[_vm._v(" Пройти опрос ")]):_vm._e()],2),_c('div',{staticClass:"sticky"},[_c('RightAside',{attrs:{"items":_vm.asideItems},on:{"medicationsClick":_vm.medicationsClick,"calendarClick":_vm.calendarClick,"nosologyClick":_vm.nosologyClick}})],1)])]),(_vm.sliderItems.length)?_c('LibrarySlider',{attrs:{"nosology":_vm.PodcastDetail.nosology[0] || '',"items":_vm.sliderItems,"video":true,"page":'PodcastDetail'}}):_vm._e(),(_vm.survey.opened && _vm.filteredSurveyQuestions.questions.length)?_c('PopUpDefault',{staticClass:"profiling-modal__survey",on:{"close":_vm.closeSurvey}},[_c('LibrarySurvey',{attrs:{"survey":_vm.filteredSurveyQuestions,"content_type":'podcast',"material_slug":_vm.PodcastDetail.slug},on:{"close":_vm.closeSurvey,"closeAfterSend":_vm.closeAfterSend}})],1):_vm._e()],1):(_vm.PodcastDetail === 404)?_c('div',[_c('Choch',{staticClass:"inPage"})],1):(_vm.PodcastDetail === false)?_c('div',[_c('Error',{staticClass:"inPage"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }